<template>
  <div class="notice">
    <div class="tab_nav">
      <div
        class="tab_nav_item"
        :class="{ tab_i_active: tab_id == 0 }"
        @click="tab_id = 0"
      >
        购票须知
        <img v-show="tab_id == 0" src="../assets/icon_xz1.png" alt="" />
      </div>
      <div
        class="tab_nav_item"
        :class="{ tab_i_active: tab_id == 1 }"
        @click="tab_id = 1"
        style="margin-left: 25rem"
      >
        入园须知
        <img v-show="tab_id == 1" src="../assets/icon_xz1.png" alt="" />
      </div>
    </div>
    <div class="main_tab">
      <div class="main_tab_one" v-if="tab_id == 0">
        <div class="main_t_info">
          <div class="info_ico"><LocalTwo size="12rem"></LocalTwo></div>
          <div class="main_t_info_title">活动地点</div>
          <div class="main_t_info_text">{{mall_name}}</div>
        </div>
        <div class="main_t_info">
          <div class="info_ico"><Time size="12rem"></Time></div>
          <div class="main_t_info_title">活动时间</div>
          <div class="main_t_info_text">
            {{ infoData.act_time_start }} - {{ infoData.act_time_end }}
          </div>
        </div>
        <div class="main_t_info" v-if="infoData.group_cnf">
          <div class="info_ico"><Watch size="12rem"></Watch></div>
          <div class="main_t_info_title">团购时间</div>
          <div class="main_t_info_text">
            {{ timestampToDateString(infoData.group_cnf.start_time * 1000) }} -
            {{ timestampToDateString(infoData.group_cnf.end_time * 1000) }}
          </div>
        </div>
        <div class="main_t_info" v-if="mall_open_time">
          <div class="info_ico">
            <HourglassNull size="12rem"></HourglassNull>
          </div>
          <div class="main_t_info_title">营业时间</div>
          <div class="main_t_info_text">
            {{ mall_open_time }}（以商场营业时间为准）
          </div>
        </div>
        <div class="main_t_info">
          <div class="info_ico">
            <StopwatchStart size="12rem"></StopwatchStart>
          </div>
          <div class="main_t_info_title">游玩时间</div>
          <div class="main_t_info_text">全天可用，如遇高峰时间，现场将进行安全限流</div>
        </div>
        <div class="main_t_info">
          <div class="info_ico">
            <CalendarThirtyTwo size="12rem"></CalendarThirtyTwo>
          </div>
          <div class="main_t_info_title">可用日期范围</div>
          <div class="main_t_info_text">
            {{
              weekday == 1 && weekend == 1
                ? "工作日、休息日"
                : weekday == 1
                ? "工作日"
                : "休息日"
            }}
          </div>
        </div>
        <div class="main_t_info" v-if="special_date && special_date.length">
          <div class="info_ico">
            <RemindDisable size="12rem"></RemindDisable>
          </div>
          <div class="main_t_info_title">不可用日期</div>
          <div class="main_t_info_text">
            <span v-for="(item, index) in special_date" :key="index"
              >{{ item }}
              {{ index + 1 == special_date.length ? "" : "、" }}</span
            >
          </div>
        </div>
        <!-- <div class="main_t_info">
          <div class="info_ico"><Schedule size="12rem"></Schedule></div>
          <div class="main_t_info_title">有效期</div>
          <div class="main_t_info_text">
            {{ act_time_start }}至{{ act_time_end }}
          </div>
        </div> -->
        <div class="main_t_info">
          <div class="info_ico" v-if="rule"><Schedule></Schedule></div>
          <div class="main_t_info_title" v-if="rule">备注</div>
          <div class="main_t_info_text" v-if="rule" style="margin-bottom:10rem;">
            {{ rule }}
          </div>
          <div class="main_t_info_title">* 所有门票一经售出概不退换</div>
        </div>
      </div>
      <div class="main_tab_two" v-if="tab_id == 1">
        <div class="main_t_info">
          <div class="info_ico"><Attention size="12rem"></Attention></div>
          <div class="main_t_info_title">注意事项</div>
          <div class="main_t_info_text">
            ·进场人员必须穿防滑袜 <br />
            ·禁止携带易燃、易爆、打火机及管制器具等危险品入场 <br />
            ·场地内禁止吸烟、禁止饮食，请勿乱扔垃圾
          </div>
        </div>
        <div class="main_t_info">
          <div class="info_ico"><Peoples size="12rem"></Peoples></div>
          <div class="main_t_info_title">适宜人群</div>
          <div class="main_t_info_text">
            ·本乐园适宜3岁以上儿童游玩，3-8岁儿童必须家长陪同进入，60岁以上老人及孕妇慎入
            <br />
            ·园内主滑梯适合5岁以上儿童使用 <br />
            ·患有传染病、心脏病、心脑血管疾病、饮酒者及身体不适者禁止进入场地
          </div>
        </div>
        <div class="main_t_info">
          <div class="info_ico"><Info size="12rem"></Info></div>
          <div class="main_t_info_title">预约信息</div>
          <div class="main_t_info_text">
            无需预约，如遇消费高峰时段您可能需要排队
          </div>
        </div>
        <div class="main_t_info">
          <div class="info_ico"><Bill size="12rem"></Bill></div>
          <div class="main_t_info_title">使用规则</div>
          <div class="main_t_info_text">
            ·门票需在活动有效期内使用 <br />
            ·入场请兑换手环，门票不作为入场凭证 <br />
            ·门票仅限本人使用，禁止转借、转增他人，或与他人共用
          </div>
        </div>
      </div>
    </div>
    <!-- <p class="title">入园须知</p> -->
    <!-- <li class="noticeItem">
      <p class="itemTitle">活动时间</p>
      <p class="itemInfo">· {{ time_start }}至{{ time_end }}</p>
    </li>
    <div class="itemInfo" v-if="rule1">
      {{rule1}}
    </div>
    <li class="noticeItem" v-if="rule">
      <p class="itemTitle">使用说明</p>
      <p class="itemInfo">{{ rule }}</p>
    </li>
    <li class="noticeItem">
      <p class="itemTitle">购票须知</p>
      <div class="itemInfo">
        <div
          v-for="(item, index) in one_data.list"
          :key="index"
          v-show="index < one_data.max"
        >
          {{ item }}
        </div>
        <span class="span_r" @click="zk(0)">{{one_data.name}} <img :class="{tr:one_data.name=='收起'}" src="../assets/tofooter.png" alt=""></span>
      </div>
    </li>
    <li class="noticeItem">
      <p class="itemTitle">入园须知</p>
      <div class="itemInfo">
        <div
          v-for="(item, index) in two_data.list"
          :key="index"
          v-show="index < two_data.max"
        >
          {{ item }}
        </div>
        <span class="span_r" @click="zk(1)">{{two_data.name}} <img :class="{tr:two_data.name=='收起'}" src="../assets/tofooter.png" alt=""></span>
      </div>

    </li> -->
  </div>
</template>

<script>
import {
  LocalTwo,
  Time,
  Watch,
  HourglassNull,
  StopwatchStart,
  CalendarThirtyTwo,
  RemindDisable,
  Schedule,
  Attention,
  Peoples,
  Info,
  Bill,
} from "@icon-park/vue"; //icon图标
export default {
  props: {
    rule: String,
    rule1: String,
    act_time_start: String,
    act_time_end: String,
    special_date: Array,
    weekday: Number,
    weekend: Number,
    mall_open_time: String,
    infoData: Object,
  },
  components: {
    LocalTwo,
    Time,
    Watch,
    HourglassNull,
    StopwatchStart,
    CalendarThirtyTwo,
    RemindDisable,
    Schedule,
    Attention,
    Peoples,
    Info,
    Bill,
  },
  data() {
    return {
      tab_id: 0,
      mall_name:'',
      one_data: {
        list: [
          "1、乐园门票仅限本人使用，禁止转借、转赠他人，或与他人共用。",
          "2、门票需在有效期内进行使用。入场须兑换手环，门票不作为入场凭证。",
          "3、如遇节假日等高峰时段，为确保您的游玩体验和场内安全，可能需要等候入场。 ",
          "4、单次游玩时间为120min，请您做好时间安排，在规定时间内入园体验。",
          "5、此票不可兑换现金，不设找赎，且一经售出概不退换。",
          "6、入园必须接受安全检查，禁止携带易燃、易爆、打火机及管制器具等危险物品入场。",
          "7、请看管好自己的随身携带物品，以免遗失。",
          "8、本票非实名票务，一旦丢失或损坏不予补发。",
          "9、场内禁止吸烟，禁止饮食，请勿乱扔垃圾，请遵循现场工作人员管理。",
          "10、最终解释权归哈尔堡所有。",
          "*所有门票一经售出概不退换",
        ],
        max: 3,
        name: "展开",
      },
      two_data: {
        list: [
          "1、所有进场人员必须穿着防滑袜。",
          "2、3岁以下儿童禁止进入场地。3-8岁儿童须家长陪同进入，若无家长陪同出现摔伤、窒息等任何意外状况的，本乐园不需要承担相关责任。",
          "3、入园前需接受安全检查，场地内严禁携带指甲刀、剪刀及其他管制刀具，禁止携带打火机等危险物品。请勿携带硬物、尖物（如玩具、发卡、饰物等）",
          "4、场地内禁止饮食。",
          "5、游玩期间，严禁在安全网内攀爬打闹，遵循场地内安全规范，听从场地安全员指挥，禁止危险动作。",
          "6、患有传染病、心脏票、心脑血管疾病、饮酒者及身体不适者禁止进场，60岁以上老人及孕妇慎入。",
          "7、为确保游玩体验与游玩安全，场地内对客流人数进行限制，高峰期需要等待入场。",
          "8、园内主滑梯适合5岁以上儿童使用。",
        ],
        max: 3,
        name: "展开",
      },
    };
  },
  created(){
    this.mall_name=this.$route.query.name;
  },
  methods: {
    timestampToDateString(timestamp) {
      const date = new Date(timestamp);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从0开始，需要加1
      const day = String(date.getDate()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}`;

      return formattedDate;
    },
    zk(num) {
      if (num == 0) {
        if (this.one_data.max < this.one_data.list.length) {
          this.one_data.max = this.one_data.list.length;
          this.one_data.name = "收起";
        } else {
          this.one_data.max = 3;
          this.one_data.name = "展开";
        }
      } else {
        if (this.two_data.max < this.two_data.list.length) {
          this.two_data.max = this.two_data.list.length;
          this.two_data.name = "收起";
        } else {
          this.two_data.max = 3;
          this.two_data.name = "展开";
        }
      }
    },
  },
};
</script>
<style scoped>
.notice {
  width: 351rem;
  padding: 18rem 16rem;
  box-sizing: border-box;
  background: linear-gradient(163deg, #fff5e4 5%, #ffffff 32%);
  border-radius: 18rem;
  margin: 15rem auto;
}
.title {
  height: 14rem;
  font-size: 14rem;
  font-family: PingFangTC, PingFangTC-Semibold;
  font-weight: 600;
  text-align: left;
  color: #171746;
  line-height: 14rem;
  margin-bottom: 18rem;
}
.noticeItem {
  margin-bottom: 15rem;
}
.itemTitle {
  font-size: 12rem !important;
  color: #171746;
  margin-bottom: 5rem;
  font-weight: bold;
}
.itemInfo {
  font-size: 12rem !important;
  color: #171746;
  margin-bottom: 3rem;
  white-space: pre-wrap;
  line-height: 20rem;
}
.itemInfo div {
  font-size: 12rem !important;
  color: #171746;
  line-height: 20rem;
}
.span_r {
  width: 50rem;
  display: block;
  margin-left: auto;
  font-size: 14rem;
  font-weight: bold;
  color: #3e8ede;
  display: flex;
}
.span_r img {
  width: 12rem;
  display: block;
}
.tr {
  transform: rotateZ(180deg);
}
.tab_nav {
  display: flex;
  margin-bottom: 10rem;
}
.tab_nav_item {
  font-size: 14rem;
  position: relative;
}
.tab_nav_item img {
  width: 15rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -10rem;
}
.tab_i_active {
  color: #ffb230;
}
.main_t_info_title {
  font-size: 12rem;
  color: #171746;
  line-height: 18rem;
  font-weight: bold;
}
.main_t_info_text {
      width: 100%;
    font-size: 12rem;
    color: #999aae;
    line-height: 22rem;
    margin-top: 3rem;
}
.main_t_info {
  margin-top: 10rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.info_ico {
  margin-right: 5rem;
}
.info_ico span {
  display: flex;
}
</style>
